@import 'styles/helpers/';
@import 'styles/typography/';

.NotesTextarea {
  border-radius: 1rem;

  textarea.ant-input {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    min-height: 11.5rem !important;
    padding: 1rem;
    padding-right: 3rem;
  }

  @media only screen and (max-width: $breakpoint-lg) {
    position: relative;
    bottom: 0;
  }

  &--Expanded {
    textarea.ant-input {
      padding-bottom: 10rem;
      padding-right: 1rem;

      @media only screen and (max-width: $breakpoint-md) {
        padding-bottom: 15rem;
      }
    }
  }

  &--Fixed {
    width: 52.8rem;
    position: fixed;
    bottom: 9rem;

    @media only screen and (max-width: $breakpoint-lg) {
      width: 100%;
      position: relative;
      bottom: 0;
    }
  }

  &-Icon {
    margin-top: 2.6rem;
    margin-left: -3.4rem;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    position: absolute;
    z-index: 1;

    @media only screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
      margin-top: 3.8rem;
    }

    &:last-of-type {
      margin-top: 6.2rem;
      margin-left: -3.2rem;

      @media only screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
        margin-top: 7.4rem;
      }
    }

    path {
      fill: $label-gray;
    }

    &:hover {
      path {
        fill: $dark-gray;
      }
    }

    &--Active {
      path {
        fill: $dark-gray;
      }
    }

    &-Wrapper {
      position: absolute;
      top: 0;
      left: 52rem;
      @media only screen and (max-width: $breakpoint-md) {
        bottom: 21rem;
      }

      &--Edit {
        bottom: 23.5rem;
        right: 4.2rem;
      }
    }
  }

  &-Image {
    &-Wrapper {
      margin: 0 1rem;
      position: absolute;
      bottom: 7.5rem;
      display: flex;
      flex-direction: row;
      padding-top: 1.2rem;
      gap: 1rem;
      width: 44rem;
      overflow: auto;

      @media only screen and (max-width: $breakpoint-lg) {
        bottom: 9rem;
        width: 74%;
      }

      @media only screen and (max-width: $breakpoint-md) {
        bottom: 15rem;
        width: 64%;
      }

      @media screen and (max-width: $breakpoint-xs) {
        display: none;
      }

      &--Edit {
        bottom: 11.7rem;
      }
    }
  }
}
